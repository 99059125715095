import React from 'react'
import S from 'react-slick'

import slide1 from '../../assets/img/home/slides/attention-training.jpg'
import slide2 from '../../assets/img/home/slides/dog-training-trick-plank.jpg'
import slide3 from '../../assets/img/home/slides/dog-walk-training.jpg'
import slide4 from '../../assets/img/home/slides/dogs-in-chicago.jpg'
import slide5 from '../../assets/img/home/slides/great-dane-training.jpg'
import slide6 from '../../assets/img/home/slides/happy-healthy-dog.jpg'
import slide7 from '../../assets/img/home/slides/leash-commands.jpg'
import slide8 from '../../assets/img/home/slides/stay-in-place.jpg'
import slide9 from '../../assets/img/home/slides/walk-in-the-city.jpg'
import slide10 from '../../assets/img/home/slides/puppy-training.jpg'

import './Slider.scss'
import './slick.scss'
import './slick-theme.scss'

const slides = [
  { src: slide1, alt: 'behavior modification' },
  { src: slide2, alt: 'dog training chicago' },
  { src: slide3, alt: 'leash reactivity' },
  { src: slide4, alt: 'doddle training chicago' },
  { src: slide5, alt: 'dog whisperer' },
  { src: slide6, alt: 'puppy training in home lessons' },
  { src: slide7, alt: 'dog leash training' },
  { src: slide8, alt: 'canine behavior modification' },
  { src: slide9, alt: 'private dog training lessons' },
  { src: slide10, alt: 'puppy training' },
]

const settings = {
  dots: true,
  variableWidth: true,
  centerMode: true,
}

class Slider extends React.PureComponent {

  state = { slides: [] }

  componentDidMount () {
    // Set this after mount or page is scrolled down because slides
    // are stacked when page is being rendered
    this.setState({ slides })
  }

  render () {
    if (this.state.slides.length === 0) return null
    return (
      <div className='Slider'>
        <S {...settings}>
          {this.state.slides.map((s, i) => (
            <div key={i} className='Slider--slide'>
              <img src={s.src} alt={s.alt} />
            </div>
          ))}
        </S>
      </div>
    )
  }

}

export default Slider