import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'

import Layout from '../components/Layout'
import Container from '../components/Container'
import Card from '../components/Card'
import Link from '../components/Link'
import Slider from '../components/Slider'
import Areas from '../components/Areas'

import './index.scss'

const Home = ({location, data}) =>
  <Layout
    location={location}
    title='Pawsome Dogs | Chicago Dog Training'
    description='We provide reward-based dog and puppy training in Chicago. Obedience training and behavior modification near you. Schedule your first lesson today!'>
    <main className='Home'>

      <section className='Home--banner'>
        <Img className='Home--banner--image' sizes={data.fileName.childImageSharp.sizes} alt='chicago dog training and behavior' />
      </section>

      <section className='Home--intro'>
        <Container className='Home--intro--container'>
          <Card className='Home--intro--container--card'>
            <h2>Chicago Dog Training</h2>
            <p>Pawsome Dogs uses positive reinforcement techniques, transforming your dog
               into a well-behaved companion. We customize our training to fit every dog's
               needs. Specializing in training and behavior modification at your home where
               training really counts.</p>
            <Link red to='/Services'>See services &rarr;</Link>
          </Card>
          <Card className='Home--intro--container--card'>
            <h2>Schedule Your First Lesson</h2>
            <ul>
              <li><span className='red'>&#9679;</span> Tired of your dog pulling you down the street?</li>
              <li><span className='red'>&#9679;</span> Embarrassed by your dog's barking and lunging?</li>
              <li><span className='red'>&#9679;</span> Need help with a new puppy?</li>
            </ul>
            <p>Pawsome Dogs can help, get started today.</p>
            <Link red to='/Form'>Contact us &rarr;</Link>
          </Card>
        </Container>
      </section>

      <section className='Home--cta'>
        <Container className='Home--cta--container'>
          <div>
            <h1>For a well-behaved dog contact us today!</h1>
          </div>
          <Link className='button cta' to='/contact'>GET STARTED</Link>
        </Container>
      </section>

      <div className='Home--areas'>
        <Areas />
      </div>

      <section className='Home--slider'>
        <Container className='Home--slider--container'>
          <Slider />
        </Container>
      </section>

    </main>
  </Layout>

export default Home

export const query = graphql`
  query {
    fileName: file(relativePath: { eq: "home/chicago-dog-training-banner.jpg" }) {
      childImageSharp {
        sizes(maxWidth: 850, quality: 90) {
          ...GatsbyImageSharpSizes_noBase64
        }
      }
    }
  }
`
